require('./bootstrap');

$(function () {
    //Pop Overs
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
    });

    //Tooltips
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function (element) {
        //new bootstrap.Tooltip(element);
        element.addEventListener("mouseover", function (event) {
            new bootstrap.Tooltip(element).show();
        });
    });

    initShowHidePassword();

    // confirm resource action
    $(".btn-confirm-action").on('click', function (e) {
        e.preventDefault();

        let href = $(this).attr('href');
        let message = $(this).data('message');
        let button_text = $(this).data('button-text');
        let method = $(this).data('method');

        confirm_modal(
            () => {
                confirm_resource_action(href, method);
            },
            message,
            button_text ? button_text : "Yes, confirm"
        );
    });
});

let initShowHidePassword = function () {
    $("[data-password]").on('click', function () {
        if ($(this).attr('data-password') == "false") {
            $(this).siblings("input").attr("type", "text");
            $(this).attr('data-password', 'true');
            $(this).addClass("show-password");
        } else {
            $(this).siblings("input").attr("type", "password");
            $(this).attr('data-password', 'false');
            $(this).removeClass("show-password");
        }
    });
};

window.parseErrorResponse = function (e, default_message = "") {
    let response_errors = [];

    if (e.response.data !== undefined && e.response.status >= 400 && e.response.status < 500) {
        if (e.response.status === 419) {
            response_errors.push("Your sessions expired. Please refresh the page.");
        } else if (e.response.data.hasOwnProperty('errors')) {
            if (typeof e.response.data.errors === 'object') {
                _.forEach(e.response.data.errors, (error) => {
                    response_errors.push(error);
                });
            } else {
                response_errors.push(...e.response.data.errors);
            }
        } else if (Array.isArray(e.response.data)) {
            response_errors.push(...e.response.data);
        } else {
            response_errors.push(e.response.data);
        }
    } else {
        if (default_message.length) {
            response_errors.push(default_message);
        } else {
            response_errors.push('Ceva nu a mers bine. Contactati echipa de suport.');
        }
    }

    return response_errors;
}

/*
 * Shows a confirmation modal.
 * */
window.confirm_modal = function (action, message, yes_button_text) {
    // set the message to display within the modal
    $('#modal-confirm .modal-body').html(message);

    // if the action is a function
    // execute the function when the 'yes' button is clicked
    if (!!(action && action.constructor && action.call && action.apply)) {
        $("#modal-confirm .modal-yes").unbind('click');

        $("#modal-confirm .modal-yes").one("click", action).one("click", function () {
            $('#modal-confirm').modal('hide');
        });
    } else if (typeof action === 'string') {
        // if the action is a string (url),
        // set the url as href for 'yes' button
        $("#modal-confirm .modal-yes").attr('href', action);
    }

    // if a custom text is defined for 'yes' button, set it instead of the default one
    if (typeof yes_button_text === 'string') {
        $('#modal-confirm .modal-yes').html(yes_button_text);
    }

    // launch the modal window
    $('#modal-confirm').modal('show');
};

window.confirm_resource_action = function (resource, method = 'POST') {
    let csrf_token = $('meta[name="csrf-token"]').attr('content');
    let form_method = method.toUpperCase() !== "GET" ? "POST" : "GET";

    $("body").append("<form id='confirm-resource-action-form' action='" + resource + "' method='" + form_method + "' style='display:none'>" +
        "<input type='hidden' name='_method' value='" + method + "'>" +
        "<input type='hidden' name='_token' value='" + csrf_token + "'>" +
        "</form>");

    $("#confirm-resource-action-form").submit();
}
